@import '../../style/theme';

.component-page-full-screen {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &-header {
    flex-shrink: 0;
    position: relative;
    z-index: $z-index-nav-bar;
  }

  &-body {
    position: relative;
    z-index: 1;
    flex-grow: 1;
    height: 100%;
  }
}

.car-list-page {
  background-color: rgba(240, 243, 247, 1);
}
