.component-icon {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  overflow: visible;
  svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
    overflow: visible;
  }
}
