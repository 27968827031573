.botton-arrow-container {
  width: 100%;
  position: relative;
  // &::after {
  //   position: absolute;
  //   bottom: -0.1rem;
  //   content: '';
  //   width: 100%;
  //   height: 0.02rem;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   background-color: #696E7C;
  // }

  .botton-arrow-triangle {
    position: absolute;
    width: 100%;
    height: 0.08rem;
    left: 50%;
    border-bottom: 0.02rem solid #696e7c;
    border-right: 0.02rem solid #696e7c;
    transform: skewX(30deg) translateX(-50%);
  }
}
