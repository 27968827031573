.component-error-boundary-section-placeholder {
  text-align: center;
  padding: 20px 30px;
}

.component-error-boundary-page-placeholder {
  text-align: center;
  overflow: hidden;

  > h1 {
    margin-top: 40px;
    font-size: 0.28rem;
  }

  > div {
    display: inline-block;
    width: 60%;
    margin-top: 30px;

    .component-button {
      background-color: #fff;
      border: 1px solid #eee;
    }
  }
}
