@import './_theme.scss';

/**
 * iphone x兼容
 */
:root {
  --origin-safe-area-inset-top: 0px;
  --origin-safe-area-inset-bottom: 0px;

  --safe-area-inset-top: var(
      --device-safe-area-inset-top,
      var(--origin-safe-area-inset-top)
  );
  --safe-area-inset-bottom: var(
      --device-safe-area-inset-bottom,
      var(--origin-safe-area-inset-bottom)
  );
  --safe-area-inset-left: 0;
  --safe-area-inset-right: 0;
}

/* 兼容 iOS < 11.2 */
@supports (width: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
    --safe-area-inset-right: constant(safe-area-inset-right);
  }
}

/* 兼容 iOS >= 11.2 */
@supports (width: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
    --safe-area-inset-right: env(safe-area-inset-right);
  }
}

* {
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
// html,
// body {
//   scroll-behavior: smooth;
// }
html {
  min-height: 100%;
}

body {
  font-size: 0.28rem;
  color: $text-color-main;
  background-color: $body-bg-color;
  font-family: $font-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a,
button,
input,
textarea {
  outline: none;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  line-height: 1;
  color: #000;
  background-color: $body-bg-color;
  text-size-adjust: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  //font: inherit;
  //font-size: 100%;
  //vertical-align: baseline;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

a {
  background-color: transparent;
  text-decoration: none;
  outline: none;
  &:active {
    outline: 0;
  }

  &:hover {
    outline: 0;
    text-decoration: none;
  }
}

abbr,
acronym {
  font-variant: normal;
}

b,
strong {
  font-weight: 700;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

sup,
sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

abbr,
acronym {
  font-variant: normal;
}

del {
  text-decoration: line-through;
}

i,
address,
caption,
cite,
code,
dfn,
em,
th,
var {
  font-style: normal;
  font-weight: 400;
}

ol,
ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

q,
blockquote {
  quotes: none;
}

q:before,
q:after,
blockquote:before,
blockquote:after {
  content: '';
}

ins {
  text-decoration: none;
}

a img,
img {
  border: none;
}

svg:not(:root) {
  overflow: hidden;
}

// // 为解决小程序端部分机型图标缺失，zzc-design-mobile图标兼容
// svg {
//   overflow: visible;
// }

figure {
  margin: 1em 0.4rem;
}

hr {
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

input[type='search'] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  appearance: none;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: 700;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

th {
  text-align: inherit;
}

iframe {
  display: block;
}
