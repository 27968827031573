@import '../../style/theme';
@import '../../style/mixin';

$height: $nav-bar-height;

$title-color: #282828;

.component-nav-bar {
  position: relative;
  // border-bottom: 1px solid #dde0e6;
  display: flex;
  height: $height;
  background-color: $nav-bar-bg-color-light;
  box-sizing: border-box;
  user-select: none;

  &-center,
  &-left,
  &-right {
    position: absolute;
    top: 0;
    height: $height;
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  &-center {
    z-index: 1;
    width: 100%;
    overflow: hidden;
    justify-content: center;
  }

  &-left {
    z-index: 3;
    left: 0;
    justify-content: flex-start;
  }

  &-right {
    z-index: 2;
    right: 0;
    justify-content: flex-end;
  }
}

.component-nav-bar-default {
  &-title {
    font-size: 0.32rem;
    font-weight: 400;
  }

  &-logo {
    width: 100%;
    height: 100%;
  }

  &-back-btn {
    box-sizing: border-box;
    min-width: 44px;
    padding: 0 15px;
    height: $height;
    line-height: $height;
    font-size: .24rem;

    &:active {
      background-color: #ffffff;
    }

    &.more-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #666666;
    }

    &.back-home {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #666666;
    }

    >i {
      font-size: 20px;
    }
  }
}

.component-nav-bar-search {
  // @include container-line(bottom);

  .component-nav-bar-center {
    //justify-content: flex-end;
    padding-right: 1.0rem;
    box-sizing: border-box;
    &.mini {
      justify-content: center;
      padding-right: 0;
    }
  }
  // 适配小程序
  .component-nav-bar-end{
    justify-content: flex-end;
  }
  .component-nav-bar-right_mini {
    //width: 0.96rem;
    background-color: #ffffff;
  }
}

.component-nav-bar-search-center-box {
  @include flex-row-horizontal-center();
  position: relative;
  box-sizing: border-box;
  width: 5.5rem;
  height: 0.72rem;
  line-height: 0.72rem;
  padding-top: 0.06rem;
  background: rgba(255, 255, 255, 1);
  color: $title-color;

  &.normal {
    box-shadow: none;
  }

  &.mini {
    width: 6.90rem;
    background: rgba(240, 243, 247, 0.798);
    border-radius: 0.04rem;
    padding-right: 0.8rem;
  }
  &.quickapp {
    // background: rgba(240, 243, 247, 0.798);
    // border-radius: 0.04rem;
    // padding-right: 0;
  }

  .icon-box {
    position: absolute;
    right: 0.12rem;
    top: 50%;
    transform: translateY(-50%);
    @include flex-row-all-center();
  }

  &.long {
    .icon-box {
      right: 0.01rem;
    }
  }

  .search-pick-item {
    // width: 1.44rem;
    width: 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .search-pick-item-text {
      @include ellipsis-single-text();
      height: .22rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      // color: rgba(51, 51, 51, 1);
      line-height: .22rem;
      @include font-size(22);
    }

    .search-pick-item-time {
      margin-top: .08rem;
      @include ellipsis-single-text();
      height: .22rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      // color: rgba(51, 51, 51, 1);
      line-height: .22rem;
      @include font-size(22);
    }
  }

  .txt-right {
    text-align: right;
  }

  .search-day-item {
    box-sizing: border-box;
    margin: 0 0.16rem;
    height: 0.28rem;
    font-size: 0.2rem;
    // color: rgba(132, 132, 132, 1);
    line-height: 0.28rem;
    text-align: center;
    padding: 0 0.18rem;
    padding-top: 0.12rem;
    padding-bottom: 0.4rem;
    box-sizing: border-box;
    font-size: 0.28rem;
    font-weight: 400;
    white-space: nowrap;

    .duration {
      @include font-size(20);
      padding-left: 0.03rem;
      padding-right: 0.09rem;
      box-sizing: border-box;
    }
  }
}

.component-nav-bar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
}
