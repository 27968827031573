.render-service-code {
  padding: 0.3rem 0;
  text-align: center;
  font-size: 0.24rem;
  color: #666;
  line-height: 2.5;
  display: none;
  .render-guard-travel {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .22rem;
    font-weight: 400;
    line-height: .22rem;
    color: #9EA1B0;
    padding: .47rem 0 .17rem 0;
    >a {
      pointer-events: none;
      // color: #9EA1B0;
    }
    .render-rectangle {
      width: .7rem;
      height: .24rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      overflow: hidden;
      margin-right: .08rem;
      transform: rotate(180deg);
      .rectangle {
        &:nth-child(1) {
          margin-left: 0rem;
        }
        &:nth-child(4) {
          margin-right: 0.065rem;
        }
      }
    }
    .render-rectangle-reverse {
      width: .7rem;
      height: .24rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      margin-left: .08rem;
      transform: rotate(180deg);
      .rectangle {
        &:nth-child(1) {
          margin-left: 0.065rem;
        }
        &:nth-child(4) {
          margin-right: 0rem;
        }
      }
    }
    .rectangle {
      width: .09rem;
      height: .33rem;
      transform: rotate(26deg);
      margin-left: .065rem;
      &1 {
      background-color: rgba(158, 161, 176, 0.59); 
      }
      &2 {
        background-color: rgba(158, 161, 176, 0.39); 
      }
      &3 {
        background-color: rgba(158, 161, 176, 0.19); 
      }
      &4 {
        background-color: rgba(158, 161, 176, 0.1); 
      }
    }
  }
  
  .render-service-href {
    margin-left: 0.2rem;
    color: #2871F7;

    &.disable {
      color: #666;
    }
  }
}

.zds-modal-btn {
  .zds-button-main {
    border: none;
    color: #2871f7;
    background-color: #fff !important;
  }
}
