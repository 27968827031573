@import '../../style/theme';
@import '../../style/mixin';

@mixin size($size) {
  @if $size == 'small' {
    height: 0.6rem;
    font-size: 0.24rem;
  } @else {
    height: 0.88rem;
    font-size: 0.36rem;
  }
}

@mixin type($type) {
  @if $type == 'primary' {
    color: #ffffff;
    background-color: $text-color-active;
    @include tap-active-color(rgba($text-color-active, 0.7));
  } @else if $type == 'warning' {
    color: #ffffff;
    background-color: $text-color-money;
    @include tap-active-color(rgba($text-color-money, 0.7));
  } @else {
    color: $text-color-main;
    background-color: #f6f6f6;
    @include tap-active-color();
  }
}

.component-button {
  @include size('large');
  @include type('default');

  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    text-align: center;
  }

  &-small {
    @include size('small');
  }

  &-primary {
    @include type('primary');
  }

  &-warning {
    @include type('warning');
  }

  &-disable {
    opacity: 0.6;
  }
}
